import { useState } from 'react';
import styled from 'styled-components';

import { useMutation } from '@tanstack/react-query';

import Button from 'components/shared/Button';
import DatePicker from 'components/shared/form/DatePicker';
import InputGroup from 'components/shared/form/InputGroup';
import StandardModal, { Actions, PatientName } from 'components/shared/StandardModal';
import { useRehabStates } from 'context/rehabStates';
import { simpleDashDate } from 'lib/date';
import { RehabStateApiName } from 'models/RehabState';
import { createLocationEpisodeRehabState } from 'services/api/locationEpisodeRehabStates';

const FormContainer = styled.div`
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
`;

export type AdmittedAtModalType = {
  setShow: (b: boolean) => void;
  invalidateData: () => void;
  locationEpisodeId: string;
  patientName: string;
  title?: string;
  toInTreatment?: boolean;
};

export default function AdmittedAtModal(props: AdmittedAtModalType) {
  const { setShow, locationEpisodeId, patientName, invalidateData, title, toInTreatment } = props;

  const { mutate, isPending } = useMutation({
    mutationFn: createLocationEpisodeRehabState,
  });

  const rehabStates = useRehabStates();

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [dateError, setDateError] = useState('');
  const convdate = simpleDashDate(startDate);

  const cancel = () => {
    setShow(false);
    setStartDate(null);
    setDateError('');
  };
  const submit = () => {
    if (!convdate) return;

    mutate(
      {
        locationEpisodeId,
        enteredAt: convdate,
        rehabStateId: rehabStates!.find(
          (x) => x.apiName === (toInTreatment ? RehabStateApiName.InTreatment : RehabStateApiName.Admission)
        )!.id,
      },
      {
        onSuccess: () => {
          invalidateData();
          cancel();
        },
      }
    );
  };

  return (
    <StandardModal title={title ?? 'Confirm Admission Date'} onCancel={cancel}>
      <PatientName>{patientName}</PatientName>
      <FormContainer>
        <InputGroup title='Actual Admission Date' error={dateError} $containerWidth='auto'>
          <DatePicker
            $hasError={!!dateError}
            maxDate={new Date()}
            selected={startDate}
            onChange={(z) => {
              setDateError(z ? '' : 'This field is required');
              setStartDate(z);
            }}
          />
        </InputGroup>
      </FormContainer>
      <Actions>
        <Button variant='ghost' onClick={cancel}>
          Cancel
        </Button>
        <Button onClick={submit} disabled={!convdate} loading={isPending}>
          Confirm
        </Button>
      </Actions>
    </StandardModal>
  );
}
