import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDrop } from 'react-dnd';
import styled from 'styled-components';

import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import PatientStatusUpdateModal from 'components/modals/PatientStatusUpdateModal';
import MoreContentRequester from 'components/MoreContentRequester';
import { portfolioLaneSortingOptions } from 'components/portfolio/helpers/filters';
import PortfolioLaneEmptyState from 'components/portfolio/PortfolioLaneEmptyState';
import Menu from 'components/shared/menu';
import { PatientState } from 'constants/filterKeysConstants';
import { Flags } from 'constants/flags';
import { useRehabStates } from 'context/rehabStates';
import { simpleDashDate } from 'lib/date';
import Episode from 'models/Episode';
import Profile, { PERMISSIONS } from 'models/Profile';
import RehabState, { RehabStateApiName } from 'models/RehabState';
import { PortfolioFilterValue } from 'models/userPreferences/PortfolioFilter';
import { createLocationEpisodeRehabState } from 'services/api/locationEpisodeRehabStates';
import { showLocationEpisode } from 'services/api/locationEpisodes';
import { indexPortfolioLane, PortfolioColumn, portfolioQueryKeys } from 'services/api/portfolio';
import { upsertPortfolioFilter } from 'services/api/preferences';
import { transitionRules } from 'services/transitionrules';
import { PortfolioFilterState } from 'stores/portfolioStore';
import { PortfolioSorts, PortfolioSortValue } from 'stores/portfolioStore';
import { usePortfolioActions, usePortfolioStore } from 'stores/portfolioStore';
import { useToastActions } from 'stores/toastStore';
import { colors } from 'styles/theme/colors';
import SortIcon from 'svg/SortIcon';

import AdmittedAtModal from '../modals/AdmittedAtModal';
import DischargeModal from '../modals/DischargeModal';

import PatientCard, { SkeletonPatientCard } from './PortfolioCard';

type PortfolioLaneType = {
  locationType?: string;
  patientState?: PatientState;
  debouncedSearch: string;
  currentRehabState: RehabState;
  profile: Profile;
  filters: PortfolioFilterState;
  initialColumnData?: PortfolioColumn;
  loadingPortfolio: boolean;
  ref?: React.Ref<HTMLDivElement>;
};

type DraggableCard = {
  type: string;
  episode: Episode;
  locationEpisodeId: string;
  rehabState: RehabState;
};

type DraggedPatientType = {
  locationEpisodeId: string;
  episode: Episode;
  intendedRehabState: RehabState;
};

export const PortfolioLane = (props: PortfolioLaneType) => {
  const {
    debouncedSearch,
    currentRehabState,
    profile,
    filters,
    patientState,
    locationType,
    initialColumnData,
    loadingPortfolio,
    ref,
  } = props;

  const scrollRef = useRef<HTMLDivElement | null>(null);

  // TODO: remove this in OLIO-291
  // rehabStates and mutateRehabState are used to move patients from admission
  // to in treatment if the user cannot create a progress update
  const rehabStates = useRehabStates();
  const { mutate: mutateRehabState } = useMutation({
    mutationFn: createLocationEpisodeRehabState,
  });

  const { mutate: mutatePortfolioFilter } = useMutation({
    mutationFn: upsertPortfolioFilter,
  });

  const sorts = usePortfolioStore((state) => state.sorts);
  const tab = usePortfolioStore((state) => state.selectedTab);
  const { setSort } = usePortfolioActions();

  const queryClient = useQueryClient();
  const default_sort = currentRehabState.inTreatment
    ? { key: currentRehabState, attributeName: 'lengthOfStay', direction: 'desc' }
    : { key: currentRehabState, attributeName: 'patientName', direction: 'asc' };

  const sort = sorts?.[currentRehabState.apiName] ?? default_sort;

  const [sortingDifferentThanInitial, setSortingDifferentThanInitial] = useState(false);
  const queryParams = useMemo(
    () => ({
      locationType: locationType,
      patientState: patientState,
      search: debouncedSearch,
      currentRehabStateApiName: currentRehabState.apiName,
      sortBy: `${sort.attributeName} ${sort.direction},patientName asc`,
      filters,
    }),
    [locationType, patientState, debouncedSearch, currentRehabState, sort, filters]
  );

  // if sorting does not change, lane uses initial data and will query subsequent pages starting at page 2
  // if sorting changes, lane controls itself starting at page 1
  const entries = useInfiniteQuery({
    queryKey: [
      ...portfolioQueryKeys.lane(currentRehabState.apiName!),
      profile?.actingClientId,
      queryParams,
      sortingDifferentThanInitial,
      initialColumnData,
    ],
    queryFn: ({ pageParam, signal }) => indexPortfolioLane({ ...queryParams, page: pageParam, pageSize: 25 }, signal),
    initialPageParam: sortingDifferentThanInitial ? 1 : 2,
    getNextPageParam: (lastPage, _pages, lastPageParam) => {
      return lastPage.meta.totalPages > lastPageParam ? lastPageParam + 1 : undefined;
    },
    initialData: initialColumnData ? { pages: [initialColumnData], pageParams: [1] } : undefined,
    enabled: sortingDifferentThanInitial,
  });

  const { addToast } = useToastActions();

  const [draggedPatient, setDraggedPatient] = useState<DraggedPatientType>();

  const rehabStateToInvalidate = useRef<string>(undefined);

  const { data: locationEpisode } = useQuery({
    queryKey: ['locationEpisode', draggedPatient?.locationEpisodeId],
    queryFn: () =>
      showLocationEpisode({
        id: draggedPatient?.locationEpisodeId ?? '',
        include: 'question_templates,owner.client.group_types,owner.client.enabled_flags,reviews,group',
      }),
    enabled: !!draggedPatient?.locationEpisodeId,
  });

  const [{ dragItem, dragItemType, isOver }, dropZone] = useDrop({
    accept: 'card',
    drop: (item: DraggableCard) => {
      if (item.rehabState.apiName === currentRehabState.apiName) return item;
      const result = canChangeState(item);

      if (result.valid) {
        rehabStateToInvalidate.current = item.rehabState.apiName;
        setDraggedPatient({
          intendedRehabState: currentRehabState,
          locationEpisodeId: item.locationEpisodeId,
          episode: item.episode,
        });
      } else {
        addToast({ text: result.message ?? 'Something went wrong.' });
      }
      return item;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      dragItem: monitor.getItem(),
      dragItemType: monitor.getItemType(),
    }),
  });

  const canChangeState = (item: any) => {
    if (!item) return { valid: false };

    const sourceStateApiName = item.rehabState.apiName;
    const destinationState = currentRehabState;

    const acceptRules = transitionRules[sourceStateApiName].accepts;
    const rejectRules = transitionRules[sourceStateApiName].rejects;
    const permission = transitionRules[destinationState.apiName].permission;
    if (permission.permission ? !profile?.permissions[permission.permission] : false) {
      return {
        valid: false,
        message: permission.message,
      };
    }
    if (acceptRules.includes(destinationState.apiName)) {
      return {
        valid: true,
        message: '',
      };
    }
    const rejectRule = rejectRules.find((x) => x.states.includes(destinationState.apiName));
    return {
      valid: false,
      message: rejectRule?.message,
    };
  };

  const invalidate = useCallback(() => {
    if (rehabStateToInvalidate.current) {
      // TODO: remove this in OLIO-291
      // if this isn't here and a user drags a patient from admission to
      // in treatment without a progress update, the AdmittedAtModal will
      // flash and then close due to order of operations
      if (draggedPatient && locationEpisode?.inQueue) {
        queryClient.invalidateQueries({
          queryKey: ['locationEpisode', draggedPatient.locationEpisodeId],
        });
      }
      queryClient.invalidateQueries({
        queryKey: ['portfolio'],
      });
    }
  }, [queryClient, draggedPatient, locationEpisode]);

  const handleSetSort = (sort: PortfolioSortValue) => {
    scrollRef.current?.scrollTo(0, 0);
    setSort(currentRehabState.apiName, sort);

    setSortingDifferentThanInitial(true);

    const updatedSorts: PortfolioSorts = { ...sorts, [currentRehabState.apiName]: sort };

    mutatePortfolioFilter({
      clientId: profile?.actingClient?.id as string,
      value: {
        ...filters,
        sorts: updatedSorts,
        providerType: tab || locationType,
      } as PortfolioFilterValue,
    });
  };

  const cards = entries.data?.pages.flatMap((page) => page.data) ?? [];

  const totalRecords = sortingDifferentThanInitial
    ? entries.data?.pages?.[0]?.meta?.totalRecords || 0
    : initialColumnData?.meta.totalRecords;

  const showSkeleton = !profile || entries.isLoading || loadingPortfolio;

  // PDD sort option is only shown in Admission and In Treatment lanes.
  // For manager users, display the sort option if client has PDD enabled for that provider type.
  // Always displayed for provider users
  const showSortByProjectedDischargeDate = (rehabState: RehabState) =>
    ((rehabState.admission || rehabState.inTreatment) &&
      profile.canActAsManager &&
      !!profile.actingClient.configForGroupType(locationType as string)?.projectedDischarge.enabled) ||
    profile.canActAsProvider;

  const sortingOptions = portfolioLaneSortingOptions(
    currentRehabState,
    showSortByProjectedDischargeDate(currentRehabState)
  );

  const canCreateProgressUpdate = useMemo(
    () =>
      draggedPatient?.episode &&
      locationEpisode &&
      profile.has(PERMISSIONS.activityProgressUpdateCreate, {
        episode: draggedPatient?.episode,
        locationEpisode: locationEpisode,
      }),
    [profile, draggedPatient, locationEpisode]
  );
  const admitInTreatmentModalTitle = useMemo(
    () => (profile?.hasFlag(Flags.ThreeLanes) ? 'Admit in Olio' : 'Start Treatment'),
    [profile]
  );

  // TODO: remove this in OLIO-291
  const moveToInTreatmentFromAdmissionWithoutProgressUpdate = useCallback(
    (patient: DraggedPatientType) => {
      mutateRehabState(
        {
          locationEpisodeId: patient.locationEpisodeId,
          enteredAt: simpleDashDate(new Date())!,
          rehabStateId: rehabStates.find((rs) => rs.apiName === RehabStateApiName.InTreatment)!.id,
        },
        {
          onSuccess: () => {
            setDraggedPatient(undefined);
            invalidate();
          },
        }
      );
    },
    [invalidate, mutateRehabState, rehabStates]
  );

  // TODO: remove this in OLIO-291
  const isMovingFromAdmissionToInTreatmentWithoutProgressUpdate = useMemo(() => {
    return (
      draggedPatient?.intendedRehabState.apiName === RehabStateApiName.InTreatment &&
      locationEpisode?.currentRehabState.apiName === RehabStateApiName.Admission &&
      !canCreateProgressUpdate
    );
  }, [draggedPatient, locationEpisode, canCreateProgressUpdate]);

  // TODO: remove this in OLIO-291
  useEffect(() => {
    if (!isMovingFromAdmissionToInTreatmentWithoutProgressUpdate) return;
    moveToInTreatmentFromAdmissionWithoutProgressUpdate(draggedPatient!);
    setDraggedPatient(undefined);
  }, [
    isMovingFromAdmissionToInTreatmentWithoutProgressUpdate,
    moveToInTreatmentFromAdmissionWithoutProgressUpdate,
    draggedPatient,
  ]);

  return (
    <SwimlaneColumn
      ref={ref}
      $dragValid={dragItemType === 'card' && canChangeState(dragItem).valid}
      $isOver={isOver}
      data-cy={`lane${currentRehabState.apiName}`}>
      {draggedPatient?.intendedRehabState.inTreatment &&
        locationEpisode &&
        (canCreateProgressUpdate ? (
          <PatientStatusUpdateModal
            title={admitInTreatmentModalTitle}
            setShow={() => setDraggedPatient(undefined)}
            locationEpisode={locationEpisode}
            patientName={locationEpisode.patient.name ?? ''}
            invalidateData={invalidate}
          />
        ) : (
          locationEpisode.inQueue && (
            <AdmittedAtModal
              title={admitInTreatmentModalTitle}
              setShow={() => setDraggedPatient(undefined)}
              invalidateData={invalidate}
              locationEpisodeId={draggedPatient.locationEpisodeId}
              patientName={draggedPatient.episode?.patient?.name ?? ''}
              toInTreatment
            />
          )
        ))}
      {draggedPatient?.intendedRehabState.admission && (
        <AdmittedAtModal
          setShow={() => setDraggedPatient(undefined)}
          invalidateData={invalidate}
          locationEpisodeId={draggedPatient?.locationEpisodeId}
          patientName={draggedPatient?.episode?.patient?.name ?? ''}
        />
      )}
      {draggedPatient?.intendedRehabState.discharged && locationEpisode && (
        <DischargeModal
          invalidateData={invalidate}
          episode={draggedPatient?.episode}
          locationEpisode={locationEpisode}
          setShow={() => setDraggedPatient(undefined)}
          patientName={draggedPatient?.episode?.patient?.name ?? ''}
        />
      )}
      <SwimlaneColumnHeader>
        <RehabStateText>{currentRehabState.state}</RehabStateText>
        <Menu>
          <Menu.Trigger>
            <div style={{ display: 'flex', alignItems: 'center', gap: '4px', cursor: 'pointer' }} role='button'>
              <SortIcon color={colors.black50} />
              <div>{totalRecords}</div>
            </div>
          </Menu.Trigger>
          <Menu.Content position='right' style={{ maxHeight: 'none' }}>
            {sortingOptions.map(({ rehabStateApiName, attributeName, direction, label }) => (
              <Menu.Item
                key={`${attributeName} ${direction}`}
                $active={sort.attributeName === attributeName && sort.direction === direction}
                onClick={() =>
                  handleSetSort({
                    key: currentRehabState.apiName,
                    attributeName,
                    direction: direction as 'asc' | 'desc',
                    rehabStateApiName,
                  })
                }>
                {label}
              </Menu.Item>
            ))}
          </Menu.Content>
        </Menu>
      </SwimlaneColumnHeader>
      <SwimlaneColumnItems
        ref={(el) => {
          dropZone(el);
          scrollRef.current = el;
        }}>
        {showSkeleton ? (
          <>
            <SkeletonPatientCard />
            <SkeletonPatientCard />
            <SkeletonPatientCard />
          </>
        ) : (
          <>
            {cards.length == 0 ? (
              <PortfolioLaneEmptyState rehabStateApiName={currentRehabState.apiName} />
            ) : (
              cards.map((x) => (
                <PatientCard
                  key={x.info.locationEpisodeId}
                  episode={x.episode}
                  info={x.info}
                  profile={profile}
                  invalidateColumn={invalidate}
                />
              ))
            )}
            <MoreContentRequester resource={entries} />
          </>
        )}
      </SwimlaneColumnItems>
    </SwimlaneColumn>
  );
};

const SwimlaneColumn = styled.div<{ $dragValid: boolean; $isOver: boolean }>`
  scroll-snap-align: center center;
  scroll-snap-stop: always;
  flex-grow: 1;
  flex-basis: 0;
  min-width: 100%;
  border-radius: ${({ theme }) => theme.dimensions.borderRadius};
  display: flex;
  flex-direction: column;
  padding: 15px 0px 12px 12px;
  border: ${({ $dragValid }) => ($dragValid ? '2px dotted ' + colors.primaryBlue : '2px solid transparent')};
  background-color: ${({ $dragValid, $isOver }) =>
    $dragValid && $isOver ? 'rgba(50, 83, 239, 0.1)' : 'rgb(226, 228, 230)'};

  @media ${({ theme }) => theme.devices.desktop} {
    min-width: 0;
  }
`;
const SwimlaneColumnHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 8px;
  padding-bottom: 24px;
  padding-right: 10px;
`;

const SwimlaneColumnItems = styled.div`
  flex: 1;
  min-height: 0;
  display: flex;
  gap: 10px;
  flex-direction: column;
  overflow-y: scroll;
  padding-right: 4px;

  &::-webkit-scrollbar {
    height: 0.5em;
    width: 0.5em;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.scrollbarGray};
    border-radius: 5pt;
  }
`;

const RehabStateText = styled.p`
  flex: 1;
`;
